import { useState, useEffect, useRef } from "react";
import Videos from "./components/Videos";
import axios from "axios";
import Help from "./components/Help";
import Video from "./components/Video";
import Examples from "./components/Examples";
import Person from "./components/Person";
import { HOST, modelName, TEXT_LENGTH } from "./config";
import { useTypingText } from "./hooks/useTypingText";
import Paywall from "./components/Paywall";
import Seo from "./Seo";


export default function App() {
  const [modelId, setModelId] = useState("elon");
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(null);
  const [charCount, setCharCount] = useState(TEXT_LENGTH);
  const [loading, setLoading] = useState(false);
  const [videoId, setVideoId] = useState("");
  const [loadingMessage, setLoadingMessage] = useState("Please wait...");
  const [percent, setPercent] = useState(0);
  const [tooltip, showTooltip] = useState(false);
  const [paywall, setPaywall] = useState(false);

  const [checkoutId, setCheckoutId] = useState(null);
  const [isComplete, setIsComplete] = useState(false);

  const videoRef = useRef(null);
  const inputRef = useRef(null);

  const { word } = useTypingText(
    Object.values(modelName),
    130,
    20
  );

  useEffect(() => {
    const modelId = localStorage.getItem("modelId");
    if (modelId) setModelId(modelId);
    const match = window.location.pathname.match(/buy\/(.+)/);
    if (match && match[1]) {
        setCheckoutId(match[1]);
        // read from local storage and load state
        const data = JSON.parse(localStorage.getItem("video"));
        if (data) {
            setSelectedVideoIndex(data.video);
            document.getElementById("text").value = data.text;
        }
    }
  }, []);

  useEffect(() => {
    const handleValidate = async () => {
      const isLocalhost = window.location.hostname === "localhost";

      try {
        const res = await axios.get(`${HOST}/video/validate/${checkoutId}?isProd=${!isLocalhost}`);
        setIsComplete(res.data.isValid);
        if (res.data.subscriptionKey) {
          localStorage.setItem("subscriptionKey", res.data.subscriptionKey);
        }
      } catch (err) {
        console.log("INVALID STRIPE KEY", err);
      }
      window.history.replaceState({}, document.title, "/");
    };
    if(checkoutId) handleValidate();
  }, [checkoutId]);

  useEffect(() => {
    if (isComplete) submit();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isComplete]);


  const submit = async () => {
    setVideoId("");
    const data = {
      video: selectedVideoIndex,
      text: document.getElementById("text").value,
      model: modelId,
    };
    if (selectedVideoIndex === null) {
      alert("Select a video");
      return;
    }
    if (data.text.length > TEXT_LENGTH) {
      alert("Text is too long");
      return;
    }
    if (data.text.length < 10) {
      alert("Text is too short");
      return;
    }
    setLoading(true);
    // check for subscription key
    let hasValidSub = false;
    const subscriptionKey = localStorage.getItem("subscriptionKey");
    if (subscriptionKey) {
      try {
        const res = await axios.get(`${HOST}/video/validate/${subscriptionKey}?isProd=true`);
        hasValidSub = res.data.isValid;
      } catch (err) {
        console.log("INVALID STRIPE KEY", err);
      }
    }



    if (!hasValidSub && !isComplete)  {
      // save to local storage
      localStorage.setItem("video", JSON.stringify(data));
      setPaywall(true);
      setLoading(false);
      return;
    }

   

    try {
      const res = await axios.post(`${HOST}/video/create`, data);
      setVideoId(res.data.id);

      const interval = setInterval(() => {
        axios.get(`${HOST}/video/status/${res.data.id}`).then(async (res) => {
          console.log(res.data);
          setPercent(Math.min(res.data.percent, 100));
          if (res.data.status === "start") {
            setLoadingMessage("Creating your video...");
          } else if (res.data.status === "done") {
            await new Promise((resolve) => setTimeout(resolve, 500));
            setLoading(false);
            clearInterval(interval);
          } else if (res.data.status === "error") {
            alert("Error creating video, try again");
            setVideoId("");
            setLoading(false);
            clearInterval(interval);
          } else {
            setLoadingMessage(res.data.status);
          }
        });
      }, 1000);
    } catch (err) {
      console.log(err);
      if (err.response.status === 429) {
        alert("Too many requests, try again later.");
      } else {
        alert("Something went wrong when creating the video, try again later");
      }
      setLoading(false);
    }
  };

  return (
    <div className="text-center">
      {paywall && <Paywall close={() => setPaywall(false)}></Paywall>}
      {/* <p className="text-2xl font-semibold text-[#FFFFFF] mb-2 p-2">♥️🇮🇩🇲🇾 If you want to skip the long queue to make your video, buy a coffee below and send me an email @ contact@elontalks.com of your selection.</p> */}
      {/* <div className="">

         <p className="left-0 md:left-auto md:right-0 top-0 -translate-y-full text-white text-[12px] text-brand-100">Server costs supported by</p>

         <iframe data-aa='2024107' src='//ad.a-ads.com/2024107?size=728x90' title="aa" style={{margin: "auto", width: "728px", height: "90px", border: "0x", padding: "0", overflow: "hidden", backgroundColor: "transparent"}} ></iframe>

      </div> */}
      {navigator.userAgent !== "ReactSnap" && <div className="pt-4">
        <p className="text-2xl font-semibold text-[#FFFFFF] mb-2 p-2 h-[112px] sm:h-auto">
          Create AI videos of&nbsp;{word}&nbsp;saying whatever you want
        </p>
      </div>}
      {navigator.userAgent === "ReactSnap" && <div className="pt-4">
        {Object.values(modelName).map((name, i) => (
          <p className="text-2xl font-semibold text-[#FFFFFF] mb-2 p-2 h-[112px] sm:h-auto">
          Create AI videos of {name} saying whatever you want
        </p>
        ))}
      </div>}

 
      {/* <div className="m-1">
        <p className="text-white text-[12px]">Please support server costs</p>
        <a
          href="https://www.buymeacoffee.com/elontalks"
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt="Support server costs"
            className="m-auto"
            src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=&slug=elontalks&button_colour=FFDD00&font_colour=000000&font_family=Poppins&outline_colour=000000&coffee_colour=ffffff"
          />
        </a>
      </div> */}
      <div className="pt-4">
        <p className="text-2xl font-semibold text-[#FFFFFF] mb-2">
          <span className="text-[#6E49FF]">Click</span> on a face
        </p>
        <Person
          modelId={modelId}
          setModelId={(e) => {
            localStorage.setItem("modelId", e);
            setModelId(e);
            videoRef.current.scrollIntoView({ behavior: "smooth" });
          }}
        />
      </div>
      <div className="pt-4">
        <p className="text-2xl font-semibold text-[#FFFFFF] mb-2" ref={videoRef}>
          <span className="text-[#6E49FF]">Select</span> Video Template
        </p>
        <Videos
          modelId={modelId}
          setSelectedVideoIndex={(e) => {
            setSelectedVideoIndex(e);
            inputRef.current.scrollIntoView({ behavior: "smooth" });
            inputRef.current.focus();
          }}
          selectedVideoIndex={selectedVideoIndex}
        />
      </div>
      <div className="mb-6">
        <p className="text-2xl font-semibold text-[#FFFFFF]">
          <span className="text-[#6E49FF]">Type</span> Text
        </p>
        <div className="block">
          <div className="w-1/2 max-w-2xl m-auto mb-2">
            <div className="flex justify-between">
              <div
                data-tip
                data-for="sadFace"
                className="help is-highlighted"
                onClick={() => showTooltip(!tooltip)}
              >
                ?
              </div>
              <Help setShowModal={showTooltip} showModal={tooltip}></Help>
              <p
                className={`text-right font-semibold pb-1 mt-1 ${
                  charCount < 0 ? "text-red-400" : ""
                }`}
              >
                {charCount}
              </p>
            </div>
            <textarea
              id="text"
              className="
              p-2
              w-full
              text-base
              text-gray-700
              bg-white
              border border-solid border-gray-300
              rounded-lg
              transition
              ease-in-out
              m-0
              resize-none
              focus:text-gray-700 focus:bg-white focus:border-[#6E49FF]-900 focus:outline-none
            "
              rows="3"
              maxLength={TEXT_LENGTH}
              placeholder={`Text ${modelName[modelId]} will say`}
              onChange={(e) => setCharCount(TEXT_LENGTH - e.target.value.length)}
              ref={inputRef}
            ></textarea>
          </div>

          {!loading && (
            <button
              onClick={submit}
              className="
              w-60
              text-lg
              p-1
              bg-[#6E49FF]
              focus:outline-none focus:none
              rounded-full
              transform transition duration-100 hover:scale-110 active:scale-90
            "
            >
              Create Video
            </button>
          )}
        </div>
      </div>
      {videoId && (
        <div className="block mb-8" style={{height: "360px"}}>
          <p className="text-2xl font-semibold text-[#FFFFFF] mb-6">
            <span className="text-[#6E49FF]">See</span> Your Video
          </p>
          <div className="w-80 h-56 bg-gray-700 rounded-lg m-auto">
            {loading && (
              <div className="pt-[12px]">
                <div className="box m-auto"></div>
                <p className="animate-bounce mt-4">{loadingMessage}</p>
                <div className="w-56 bg-gray-200 rounded-full m-auto">
                  <div
                    className="bg-[#6E49FF] text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-l-full"
                    style={{ width: `${percent}%` }}
                  >
                    {percent}%
                  </div>
                </div>
                <p>This can take up to a few mins to process.</p>
              </div>
            )}
            {!loading && (
              <Video setVideoId={setVideoId} videoId={videoId}></Video>
            )}
          </div>
        </div>
      )}
      <Examples></Examples>
      {navigator.userAgent === "ReactSnap" && <Seo></Seo>}
    </div>
  );
}
